import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../layouts/home";
import SEO from "../components/SEO";
// import TaxServices from "../components/TaxServices";

import contentParser from "gatsby-wpgraphql-inline-images";
import { inlineUrls } from "../utils";
import Social from "../components/Social/sharer";
import Instagram from "../components/Social/instagram";

class About extends React.Component {
  render() {
    const {
      location,
      data: {
        wordPress: {
          pageBy: {
            title,
            acf_subtitle: { subtitle },
            excerpt,
            content,
            featuredImage,
            acf_vimeo_video: { video },
            acf_about: {
              // ourJourney,
              // ourJourneyCopy,
              ourCulture,
              ourCultureCopy,
              founderPicture,
              founderBio,
            },
          },
        },
      },
    } = this.props;

    // console.log(this.props);

    return (
      <Layout location={location} cssClass="about">
        <SEO title={title} description={excerpt} />
        <section id="header" className="p-top p-bottom text-center bg-charcoal">
          <div className="featured">
            <h1>{title}</h1>
            <div className="lead">
              <p>{subtitle}</p>
            </div>
          </div>
        </section>
        <section id="excerpt" className="bg-light-gray p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {contentParser({ content: excerpt }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <Social location={location} />
        <section
          id="body"
          className="b-top b-bottom mt-0 relative bg-light-gray"
        >
          <div className="container-full">
            <div className="row collectivemastery">
              <div className="col-lg-6">
                <div className="img-overview">
                  {featuredImage && featuredImage.imageFile && (
                    <Img
                      className="bg"
                      alt={featuredImage.altText}
                      fluid={featuredImage.imageFile.childImageSharp.fluid}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6 p-top p-bottom p-left p-right aboutus">
                {contentParser({ content }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        {/* {ourJourneyCopy && (
          <section className="mt-0 bg-charcoal p-top">
            <div className="container">
              <div className="row">
                <div className="col-md-6 lead">
                  <h2>{ourJourney}</h2>
                </div>
                <div className="col-md-6">
                  {contentParser({ content: ourJourneyCopy }, inlineUrls)}
                </div>
              </div>
            </div>
          </section>
        )}
        <TaxServices /> */}
        <section id="result" className="bg-white p-top p-bottom mt-0">
          <div className="container">
            <div className="row">
              <div id="aboutvideo" className="offset-md-2 col-md-8 ext-center">
                <iframe
                  className="video"
                  style={{ border: 0 }}
                  src={`https://player.vimeo.com/video/${video}?title=0&amp;byline=0&amp;portrait=0&amp;color=304cd1&amp;loop=0`}
                  width="800"
                  height="450"
                  title="About balcony8"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section id="result" className="bg-charcoal p-top p-bottom mt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2>{ourCulture}</h2>
              </div>
              <div className="col-lg-6">
                {contentParser({ content: ourCultureCopy }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <section
          id="founder"
          className="b-top b-bottom mt-0 relative bg-light-gray"
        >
          <div className="container-full">
            <div className="row collectivemastery">
              <div className="col-lg-6">
                <div className="img-overview">
                  {founderPicture && founderPicture.imageFile && (
                    <Img
                      className="bg"
                      alt={founderPicture.altText}
                      fluid={founderPicture.imageFile.childImageSharp.fluid}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-6 p-top p-bottom p-left p-right aboutus">
                {contentParser({ content: founderBio }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        <Instagram />
      </Layout>
    );
  }
}

export default About;

export const aboutQuery = graphql`
  {
    wordPress {
      pageBy(uri: "about") {
        title
        acf_subtitle {
          subtitle
        }
        excerpt
        content
        featuredImage {
          ...fragmentAboutFeaturedImage
        }
        acf_vimeo_video {
          video
        }
        acf_about {
          ourJourney
          ourJourneyCopy
          ourCulture
          ourCultureCopy
          founderPicture {
            ...fragmentFounderPicture
          }
          founderBio
        }
      }
    }
  }
`;

export const fragmentAboutFeaturedImage = graphql`
  fragment fragmentAboutFeaturedImage on WordPress_MediaItem {
    altText
    sourceUrl
    srcSet
    content
    mediaItemId
    modified
    srcSet
    imageFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const fragmentFounderPicture = graphql`
  fragment fragmentFounderPicture on WordPress_MediaItem {
    altText
    sourceUrl
    srcSet
    content
    mediaItemId
    modified
    srcSet
    imageFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
